.contact-item {
  width: 100%;
  display: flex;
}

.contact-item .icon {
  padding: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #32475c;
  background: #eee;
}

.contact-item:last-child .icon {
  border-bottom: none;
}

.contact-item .title {
  width: 80%;
  width: calc(100% - 55px);
  font-weight: 700;
  opacity: 0.9;
  padding-left: 15px;
  font-size: 0.9em;
}

.contact-item .title.only {
  margin-top: 10px;
}

.contact-item .description {
  width: 80%;
  width: calc(100% - 55px);
  color: #334960;
}
