.educationlist {
  margin-top: 5px;
  margin-bottom: 0;
  position: relative;
  padding: 1em 0;
  list-style: none;
}

.educationlist:before {
  width: 5px;
  height: 100%;
  position: absolute;
  left: 11.5%;
  top: 0;
  content: " ";
  display: block;
  background: #32475c;
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #32475c 7%,
    #32475c 89%,
    #ffffff 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #ffffff),
    color-stop(7%, #32475c),
    color-stop(89%, #32475c),
    color-stop(100%, #ffffff)
  );
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #32475c 7%,
    #32475c 89%,
    #ffffff 100%
  );
  background: -o-linear-gradient(
    top,
    #ffffff 0%,
    #32475c 7%,
    #32475c 89%,
    #ffffff 100%
  );
  background: -ms-linear-gradient(
    top,
    #ffffff 0%,
    #32475c 7%,
    #32475c 89%,
    #ffffff 100%
  );
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #32475c 7%,
    #32475c 89%,
    #ffffff 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}
.education {
  display: flex;
}

.education .year {
  width: 25%;
  background: #fff;
  padding: 10px 0;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  z-index: 1;
  align-self: flex-start;
  font-size: 0.8em;
}

.education .description {
  width: 75%;
  display: inline-block;
  background: #eee;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: 0.8em;
}

.education .description:after {
  content: "";
  position: absolute;
  top: 8px;
  right: 0;
  left: -16px;
  height: 0;
  width: 0;
  border: solid transparent;
  border-right-color: #eee;
  border-width: 8px;
  pointer-events: none;
  z-index: 2;
}

.education .description h3 {
  font-size: 1.2em;
  margin: 0;
  padding: 0;
  font-weight: 700;
}

.education .description p {
  margin-top: 5px;
  padding: 0;
}

.education .course {
  border: 1px solid gray;
  border-radius : 0.5em;
  padding: 5px;
  background-color: #fff;
  color : #334960;
  font-style: italic;
    font-size: 0.95em;
}
