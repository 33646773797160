.jobs {
    padding-right: 15px;
}
.job .details {
  padding: 10px;
  margin-bottom: 5px;
  background: #eee;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: 0.9em;
}

.job .where {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.job .year {
  opacity: 0.7;
  font-size: 0.8em;
  line-height: 1.8571375;
  text-transform: capitalize;
}

.job .profession {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.job .location {
  opacity: 0.7;
  font-size: 0.8em;
  line-height: 1.8571375;
}

.job .description {
  line-height: 1.3em;
}

.job .highlight {
  flex: 0 1 100%;
  padding-bottom: 2px;
  font-weight: 700;
  font-size: 0.9em;
}
.job .highlights {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.job .highlights .tasks {
  flex: 1 0 52%;
  line-break: auto;
  white-space: normal;
  margin: 0;
  font-size: 0.9em;
  padding-left: 25px;
}

.job .highlights .tasks li {
  list-style-type: circle;
  padding-left: 0em;
}
.job .highlights .env {
  flex: 1 0 35%;
}
.job .highlight .env .skill {
  font-style: normal;
}

.job .job-details {
  width: 100%;
}


