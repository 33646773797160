.container {
  background: #fff;
  padding: 10px 25px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 60% 40%;
  width: 210mm;
  font-size: 11px;
}

.box {
  margin-bottom: 0em;
}

.box h2 {
  color: #227c74;
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  margin: 5px 0;
}

.badge {
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 4px 8px;
  border: 1px solid #32475c;
  background-color: transparent;
  color: #32475c;
  font-weight: 900;
  position: relative;
  font-size: 0.65em;
}
