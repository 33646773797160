@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800);
.header {
  grid-column: 1 / 3;
}

.header h1,
.header h2 {
  margin: 0;
  padding: 0;

  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -1px;
  text-align: center;
}

.header h1 {
  font-size: 2.3em;
  font-weight: 800;
  line-height: 1.5em;
}

.header h2 {
  font-size: 1.5em;
  color: #334960;
  opacity: 0.7;
}

.header h1 sup {
  opacity: 0.5;
}

.header:after {
  width: 100%;
  height: 3px;
  border-bottom: 1px solid #ddd;
  margin-top: 5px;
  content: "";
  display: block;
}

.contact-item {
  width: 100%;
  display: flex;
}

.contact-item .icon {
  padding: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #32475c;
  background: #eee;
}

.contact-item:last-child .icon {
  border-bottom: none;
}

.contact-item .title {
  width: 80%;
  width: calc(100% - 55px);
  font-weight: 700;
  opacity: 0.9;
  padding-left: 15px;
  font-size: 0.9em;
}

.contact-item .title.only {
  margin-top: 10px;
}

.contact-item .description {
  width: 80%;
  width: calc(100% - 55px);
  color: #334960;
}

.skill-level {
  background-color: #227c74;
  border-radius: 4px;
  color: #fff;
  padding: 1px 8px;
  font-size: 0.75em;
  position: absolute;
  margin: 1px 10px;
}

.item-skills {
  color: #334960;
  padding: 2px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1em;
  font-weight: 600;
}
.language {
  margin-left: 25px;
}

.educationlist {
  margin-top: 5px;
  margin-bottom: 0;
  position: relative;
  padding: 1em 0;
  list-style: none;
}

.educationlist:before {
  width: 5px;
  height: 100%;
  position: absolute;
  left: 11.5%;
  top: 0;
  content: " ";
  display: block;
  background: #32475c;
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #32475c 7%,
    #32475c 89%,
    #ffffff 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}
.education {
  display: flex;
}

.education .year {
  width: 25%;
  background: #fff;
  padding: 10px 0;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  z-index: 1;
  align-self: flex-start;
  font-size: 0.8em;
}

.education .description {
  width: 75%;
  display: inline-block;
  background: #eee;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: 0.8em;
}

.education .description:after {
  content: "";
  position: absolute;
  top: 8px;
  right: 0;
  left: -16px;
  height: 0;
  width: 0;
  border: solid transparent;
  border-right-color: #eee;
  border-width: 8px;
  pointer-events: none;
  z-index: 2;
}

.education .description h3 {
  font-size: 1.2em;
  margin: 0;
  padding: 0;
  font-weight: 700;
}

.education .description p {
  margin-top: 5px;
  padding: 0;
}

.education .course {
  border: 1px solid gray;
  border-radius : 0.5em;
  padding: 5px;
  background-color: #fff;
  color : #334960;
  font-style: italic;
    font-size: 0.95em;
}

.jobs {
    padding-right: 15px;
}
.job .details {
  padding: 10px;
  margin-bottom: 5px;
  background: #eee;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: 0.9em;
}

.job .where {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.job .year {
  opacity: 0.7;
  font-size: 0.8em;
  line-height: 1.8571375;
  text-transform: capitalize;
}

.job .profession {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.job .location {
  opacity: 0.7;
  font-size: 0.8em;
  line-height: 1.8571375;
}

.job .description {
  line-height: 1.3em;
}

.job .highlight {
  flex: 0 1 100%;
  padding-bottom: 2px;
  font-weight: 700;
  font-size: 0.9em;
}
.job .highlights {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.job .highlights .tasks {
  flex: 1 0 52%;
  line-break: auto;
  white-space: normal;
  margin: 0;
  font-size: 0.9em;
  padding-left: 25px;
}

.job .highlights .tasks li {
  list-style-type: circle;
  padding-left: 0em;
}
.job .highlights .env {
  flex: 1 0 35%;
}
.job .highlight .env .skill {
  font-style: normal;
}

.job .job-details {
  width: 100%;
}



.container {
  background: #fff;
  padding: 10px 25px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 60% 40%;
  width: 210mm;
  font-size: 11px;
}

.box {
  margin-bottom: 0em;
}

.box h2 {
  color: #227c74;
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  margin: 5px 0;
}

.badge {
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 4px 8px;
  border: 1px solid #32475c;
  background-color: transparent;
  color: #32475c;
  font-weight: 900;
  position: relative;
  font-size: 0.65em;
}

@page {
  size: A4;
  margin: 0;
  zoom: 100%;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    zoom: 100%;
    
  }
}
* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", Arial, Tahoma;
  font-weight: 400;
  color: #363636;
  background: #334960;
  font-size: 12px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.body-print {
  background: transparent;
  width: 210mm;
  height: 297mm;
  margin: 0;
  padding: 0;
}

.body-print .container {
  width: 100%;
  margin: 0;
}

a {
  color: inherit;
}

.btn-export {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: white;
  border: 0;
  align-self: center;
  position: absolute;
  left: calc((100% - 210mm) / 2 + 210mm + 50px);
  cursor: pointer;
  transition: transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
  box-shadow: 0 0 0.25em 0.5em 0 rgba(255, 255, 255, 0.5);
}

.btn-export:hover,
.btn-export:focus {
  outline: none;
  transform: scale(1.05);
  box-shadow: 0 0 1.25em 0 rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 900px) {
  body {
    width: 100%;
    margin: 0;
  }

  .btn-export {
    position: inherit;
    display: block;
    margin: 20px auto;
  }

  .container {
    width: 100%;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  .header {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .work {
    grid-row: 3 / 4;
  }

  .other {
    grid-row: 2 / 3;
  }

  .jobs {
    padding-right: 0;
  }
}

