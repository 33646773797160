@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800);
@page {
  size: A4;
  margin: 0;
  zoom: 100%;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    zoom: 100%;
    
  }
}
* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", Arial, Tahoma;
  font-weight: 400;
  color: #363636;
  background: #334960;
  font-size: 12px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.body-print {
  background: transparent;
  width: 210mm;
  height: 297mm;
  margin: 0;
  padding: 0;
}

.body-print .container {
  width: 100%;
  margin: 0;
}

a {
  color: inherit;
}

.btn-export {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: white;
  border: 0;
  align-self: center;
  position: absolute;
  left: calc((100% - 210mm) / 2 + 210mm + 50px);
  cursor: pointer;
  transition: transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
  box-shadow: 0 0 0.25em 0.5em 0 rgba(255, 255, 255, 0.5);
}

.btn-export:hover,
.btn-export:focus {
  outline: none;
  transform: scale(1.05);
  box-shadow: 0 0 1.25em 0 rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 900px) {
  body {
    width: 100%;
    margin: 0;
  }

  .btn-export {
    position: inherit;
    display: block;
    margin: 20px auto;
  }

  .container {
    width: 100%;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  .header {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .work {
    grid-row: 3 / 4;
  }

  .other {
    grid-row: 2 / 3;
  }

  .jobs {
    padding-right: 0;
  }
}
