.header {
  grid-column: 1 / 3;
}

.header h1,
.header h2 {
  margin: 0;
  padding: 0;

  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -1px;
  text-align: center;
}

.header h1 {
  font-size: 2.3em;
  font-weight: 800;
  line-height: 1.5em;
}

.header h2 {
  font-size: 1.5em;
  color: #334960;
  opacity: 0.7;
}

.header h1 sup {
  opacity: 0.5;
}

.header:after {
  width: 100%;
  height: 3px;
  border-bottom: 1px solid #ddd;
  margin-top: 5px;
  content: "";
  display: block;
}
